import NavigationSubMenu from './navigation-submenu';

export default class NavigationDrawer {
    constructor(element, parent) {
        this.element = element;
        this.parent = parent;
        this.submenus = this.element.querySelectorAll(
            '[data-navigation-parent]'
        );
        this.init();
    }

    init() {
        this.submenus.forEach(submenu => {
            // eslint-disable-next-line no-new
            new NavigationSubMenu(submenu);
        });
    }
}
